@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables.scss";

.multiSelectToolbar {
  display: flex;
  flex: 1;
  align-items: center;
  min-height: 48px;
  left: 0 !important;

  > div {
    color: $secondary-color;
    font-size: 14px;
    line-height: 48px;
    text-align: left;
    height: 100%;

 

    &.activeToolbarItemsCount {
      padding-left: 10px;
      font-size: 14px;
      font-weight: 600;
    }

    &.activeToolbarSum {
      padding-left: 10px;
      font-size: 14px;
    }

    &.activeToolbarButtons {
      margin-left: 100px;

    }

    &.activeToolbarClear {
      margin-left: 10px;
    }
  }
}
