@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.aggregationPage {
  + .aggregationPage {
    margin-top: 15px;
  }
}

.aggregationTable {
  tbody {
    max-height: 55vh !important;

    @media only screen and (max-height: 800px) {
      max-height: 72vh !important;
    }
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 40px;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 18%;
      }

      &:nth-child(4) {
        width: 16%;
      }

      &:nth-child(5) {
        width: 9%;
      }

      &:nth-child(6) {
        width: 16%;
      }

      &:nth-child(7) {
        width: 12%;
      }

      &:nth-child(8) {
        width: 5%;
      }

      &:nth-child(9) {
        width: 60px;
      }
    }
  }
}

.aggregationTableTotals {
  background: $athens-light-gray;
}

.expandIcon {
  margin: 0;
  padding: 0;
  svg {
    fill: black;
  }
}

.expandedRow {
  background: $dropdown-selected-color;
}

.aggregationClient {
  color: $primary-color;
}

.aggregationCountLabel {
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  background: $derby;
  border-radius: 50%;
  text-align: center;
}

.exportButton {
  position: absolute;
  top: 12px;
  right: 20px;
  color: $secondary-color;
  font-size: $main-font-size;
  line-height: 20px;
  cursor: pointer;
}

.rowTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 5px;
  margin-top: 5px;
  color: $black;
  // left align
  text-align: left;
}

.submitModalButton {
  width: 100%;
}

.activeToolbar {
  order: 1;

  > div {
    &:nth-child(1) {
      width: 39%;
    }

    &:nth-child(2) {
      width: 18%;
    }

    &:nth-child(3) {
      width: 4%;
    }

    &:nth-child(4) {
      width: 19.5%;
    }

    &:nth-child(5) {
      width: 12.5%;
    }
  }
}


.label{
  margin-top: 8px;
}

.wireFeeCheckboxWrapper {
  text-align: left;
}

.modalRoot {
  max-height: 90vh;
  overflow: hidden;
}

.modalBody {
  max-height: calc(90vh - 114px);
  overflow-x: hidden;
  overflow-y: auto;

  @include thin-scrollbar;
}


.textFormField {
  :global {
    .MuiInput-root {
      height: 48px;
    }

    .MuiInput-input {
      font-size: 14px;
    }
  }
}


.dateFormField {
  width: 100%;
  height: 48px !important;

  :global {
    .MuiInput-root {
      padding: 8px 14px;
      height: 48px !important;
      width: 100% !important;
    }

    .MuiInput-input {
      font-size: 14px;
    }
  }
}