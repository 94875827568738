@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.table {
  border-radius: 10px;
  table tbody {
    max-height: 50vh !important;
  }

  tr {
    td {
      :global {
        .MuiSelect-select {
          padding: 2px 7px;
        }
        .MuiSelect-icon {
          top: 4px;
          padding: 2px 5px;
        }
        .MuiCheckbox-root {
          padding: 0;
        }
        .MuiFormControlLabel-root {
          margin-left: 0;
        }
      }
    }

    &.newRow {
      background-color: $mabel !important;
    }
  }
}

.fullTable {
  td,
  th {
    &:nth-child(1) {
      width: 15%;
    }

    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(9) {
      width: 110px;
    }
    &:nth-child(12) {
      width: 70px;
    }
  }
}

.hiddenColsTable {
  td,
  th {
    &:nth-child(1) {
      width: 15%;
    }

    &:nth-child(4),
    &:nth-child(7) {
      width: 110px;
    }
    &:nth-child(10) {
      width: 70px;
    }
  }
}

.monthlySalesBySKUTable {
  display: block;
  width: 100%;

  &.monthlySalesBySKUTableWithSidebar {
    table {
      thead, tbody, tfoot {
        max-width: calc(100vw - 112px - $drawer-expanded-width - $drawer-margin);
      }
    }
  }

  &.monthlySalesBySKUTableWithNotes {
    table {
      thead, tbody, tfoot {
        max-width: calc(100vw - 112px - $drawer-collapsed-width - $drawer-margin - $notesDrawerWidth);

        @media only screen and (min-width: 1800px) {
          max-width: calc(100vw - 112px - $drawer-collapsed-width - $drawer-margin - $bigNotesDrawerWidth);
        }
      }
    }

    &.monthlySalesBySKUTableWithSidebar {
      table {
        thead, tbody, tfoot {
          max-width: calc(100vw - 112px - $drawer-expanded-width - $drawer-margin - $notesDrawerWidth);

          @media only screen and (min-width: 1800px) {
            max-width: calc(100vw - 112px - $drawer-expanded-width - $drawer-margin - $bigNotesDrawerWidth);
          }
        }
      }
    }
  }

  table {
    thead, tbody, tfoot {
      max-width: calc(100vw - 112px - $drawer-collapsed-width - $drawer-margin);
    }

    thead, tfoot {
      display: block;
      overflow-y: auto;
      table-layout: unset;
      margin: 0;

      // Hide scrollbar
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      tr {
        display: table;
        width: 100%;
        table-layout: fixed;
        border-spacing: 0;
      }
    }

    tbody {
      border-collapse: separate !important;
      > div > div {
        overflow: inherit !important; //allows for sticky left columns
      }
    }
  }

  thead {
    tr {
      &:nth-child(1) {
        th {
          &:nth-child(5) {
            left: 560px;
            width: 120px;
            position: sticky !important;
            z-index: 1;
            text-align: left;
            background: $geyser;
          }
        }
      }
    }
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 200px;
        left: 0;
      }

      &:nth-child(2) {
        width: 120px;
        left: 200px;
      }

      &:nth-child(3) {
        width: 120px;
        left: 320px;
      }

      &:nth-child(4) {
        width: 120px;
        left: 440px;
        box-shadow: 13px 20px 20px 0 rgba(0, 0, 0, 0.2);
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        position: sticky !important;
        z-index: 1;
        text-align: left;
        background-color: inherit !important;
      }

      &:nth-child(n+5) {
        width: 120px;
      }
    }
  }

  tfoot {
    tr {
      td {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          background: $slate-gray !important;
        }

        &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)) {
          z-index: 2;
        }
      }
    }
  }
}

.skuTypesList {
  width: 97%;
}

.distributionTitle {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: $secondary-color;
}

.distributionOptions {
  width: 118px;
  height: 36px;
}

.distributionOptionsSmall {
  width: 100px;
  height: 36px;
}

.activeToolbar {
  order: 1;

  > div {
    width: 13%;

    &:nth-child(1) {
      width: 22%;
    }

    &:nth-child(6),
    &:nth-child(9) {
      width: 110px;
    }
    &:nth-child(12) {
      width: 70px;
    }
  }
}

.monthlySalesBySKUActiveToolbar {
  overflow-x: auto !important;
  display: inline-flex !important;
  width: 100% !important;
  flex-shrink: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  > div {
    flex-shrink: 0;

    &:nth-child(1) {
      position: sticky !important;
      background-color: $white !important;
      left: 0;
      width: 200px;
      text-align: left;
      z-index: 1;
    }

    &:nth-child(2) {
      position: sticky !important;
      background-color: $white !important;
      left: 200px;
      z-index: 1;
      min-width: 120px;
      width: 120px;
    }

    &:nth-child(3) {
      position: sticky !important;
      background-color: $white !important;
      left: 320px;
      z-index: 1;
      min-width: 120px;
      width: 120px;
      //box shadow on the right
    }

    &:nth-child(4) {
      position: sticky !important;
      background-color: $white !important;
      left: 440px;
      z-index: 1;
      min-width: 120px;
      width: 120px;
      box-shadow: 13px 20px 20px 0 rgba(0, 0, 0, 0.2);
    }

    &:nth-child(n+5) {
      min-width: 120px;
      width: 120px !important;
    }
  }
}

.updateWarning {
  font-size: 14px;
  line-height: 25px;
  height: 25px;
  width: 190px;
  text-align: center;
}

.titleContainer {
  display: flex;
}

.warningIcon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.asOfLabel {
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
}

.clientUserTitle {
  line-height: normal;
}
.editLink {
  font-size: 12px;
  line-height: 23px;
  margin-left: 10px;
}

.highlighted {
  background: $geyser;
}

.highlightedCell {
  background: $catskill-white;
}
