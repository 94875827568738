@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.container {
  margin: 10px 40px 10px 32px;
  border: 1px solid $border-color;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  border-radius: $main-border-radius;
}

.header {
  padding: 7px 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  flex-direction: column;
}

.titleContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}

.title {
  font-size: 18px;
  font-weight: 800;
}

.subTitle {
  font-size: 12px;
  color: $secondary-color;
  font-weight: 700;
}

.editLink {
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
}

.subTitleLoader {
  display: inline-block;
}

.data {
  display: flex;
  align-items: flex-start;
  gap: 100px;
  flex-wrap: wrap;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.sectionTitle {
  color: $secondary-color;
  padding: 0 0 7px;
  font-size: 16px;
  font-weight: 800;
  line-height: 150%;
  border-bottom: 1px solid $button-border-color;
  align-self: stretch;
  text-align: left;
}

.sectionData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.dataRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 16px;
  padding: 7px 17px 7px 7px;

  &:nth-child(even) {
    background-color: $row-background-color;
  }

  &:hover {
    background: $border-color;
  }
}

.dataRowFull {
  flex-direction: column;
  align-items: flex-start;
  background: $white !important;

  .value {
    padding: 2px 0 1px;
  }
}

.label {
  color: $slate-gray;
  font-size: 12px;
  line-height: 1.25;
  font-weight: 700;
  text-transform: capitalize;
  vertical-align: center;
}

.value {
  padding: 2px 13px 1px;
  color: $secondary-color;
  font-size: 12px;
  line-height: 1.25;
  font-weight: 700;

  :global {
    [class*="SelectField_root"] {
      background: $white;
    }
  }
}
